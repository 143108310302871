import { useState } from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";

import Top from "./components/Top";
import Quest from "./components/Quest";
import Quest2 from "./components/Quest_2";
import Goal from "./components/Goal";

export default function App() {
  const [prevPath, setPrevPath] = useState("");

  const init = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div style={{ backgroundColor: "black", color: "white" }}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Top />} />
          <Route
            path="quest_1"
            element={
              <Quest
                title="爆弾を探せ！"
                answer="べにづる"
                next="/quest_2"
                hint="数字は画数を表している。"
                buttonMessage="爆弾がある場所はここだ"
                prevPath={prevPath}
                setPrevPath={setPrevPath}
                init={init}
              />
            }
          />

          <Route
            path="quest_2"
            element={
              <Quest2
                title="爆弾解除コードを示せ！"
                answer="キャンセル"
                next="/goal"
                hint="これまでの謎を振り返ろう。"
                buttonMessage="解除する"
                prevPath={prevPath}
                setPrevPath={setPrevPath}
                init={init}
              />
            }
          />
          <Route
            path="/goal"
            element={
              <Goal title="解除成功！！！" prevPath={prevPath} init={init} />
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}
