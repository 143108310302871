import "./styles.css";
import { FC, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import img from "../assets/hannin.jpg";

const useStyles = makeStyles({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column"
  },
  img: {
    width: "100%"
  },

  input: {
    width: "40%"
  },
  message: {
    display: "flex",
    justifyContent: "center",
    borderRadius: 10
  }
});

interface GoalProp {
  title: string;
  prevPath: string;
  init: () => void;
}

const Goal: FC<GoalProp> = ({ title, prevPath, init }) => {
  const classes = useStyles();

  const isCorrectPath = () => {
    return true;
    // 不正アクセス用
    // if (!prevPath) return false;
    // const p = prevPath.split("/").slice(-1)[0];
    // return prevPath === "quest_2";
  };

  useEffect(() => {
    init();
  });

  return (
    <div className={classes.root}>
      {isCorrectPath() ? (
        <Box
          mx={50}
          my={5}
          sx={{
            border: "solid 6px white",
            borderRadius: 8,
            padding: 10,
            margin: "10 0",
            backgroundColor: "#950623"
          }}
        >
          <Box my={2}>
            <h1>{title}</h1>
          </Box>
          <Box my={10} sx={{ display: "flex", justifyContent: "center" }}>
            <img className={classes.img} src={img} alt="img" />
          </Box>
          <Box my={5} className={classes.message} sx={{ textAlign: "center" }}>
            <p
              style={{
                fontSize: 16,
                fontFamily: "'メイリオ'",
                lineHeight: "3em"
              }}
            >
              さすが！千葉キャンパスの生徒たちだ！
              <br />
              実は爆弾はありません！
              <br />
              わたくし細田が、千葉のキャンフェスのフィナーレを
              <br />
              もっと盛り上げようと思って
              <br />
              仕掛けたドッキリでした！
              <br />
              怒らないでね。
              <br />
              制限時間内に解けたグループは、
              <br />
              終わるまで内緒にしていてくださいね！
              <br />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "70px 0 0 0"
                }}
              >
                <Button
                  variant="contained"
                  onClick={() => {
                    window.open(
                      "https://docs.google.com/forms/d/e/1FAIpQLSe8hV6wGv2_emEYC4dEKmEVBFrMsv0v6WGJxSjg0gmAKpqRqw/viewform",
                      "_blank"
                    );
                  }}
                  sx={{
                    "&.MuiButton-contained": {
                      backgroundColor: "black",
                      "&:hover": {
                        boxShadow:
                          "rgba(0, 0, 0, 0.3) 0px 10px 36px 0px, rgba(0, 0, 0, 0.3) 0px 0px 0px 1px"
                      }
                    },
                    "&.MuiButton-root": {
                      fontWeight: "bold"
                    }
                  }}
                >
                  最後に達成報告をしよう
                </Button>
              </Box>
              <br />
            </p>
          </Box>
        </Box>
      ) : (
        <Box>不正アクセス</Box>
      )}
    </div>
  );
};

export default Goal;
