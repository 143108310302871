import "./styles.css";
import { useState, FC, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate, useLocation } from "react-router-dom";

import img from "../assets/q3.jpg";
import pre_img from "../assets/q2.png";
import q2_answer from "../assets/q2_answer.jpg";

const useStyles = makeStyles({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column"
  },
  img: {
    width: "100%"
  },
  input: {
    width: "40%",
    backgroundColor: "white"
  },
  inputBase: {
    color: "#950623"
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: "24",
    p: 4
  }
});

interface QuestProp {
  title: string;
  answer: string;
  next: string;
  hint: string;
  buttonMessage: string;
  prevPath: string;
  setPrevPath: (prev: string) => void;
  init: () => void;
}

const Quest: FC<QuestProp> = ({
  title,
  answer,
  next,
  hint,
  buttonMessage,
  prevPath,
  setPrevPath,
  init
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [req, setReq] = useState("");

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleClick = () => {
    if (req !== answer) {
      window.alert("それは違うようだ…");
      return;
    }
    navigate(next);
  };

  useEffect(() => {
    init();
    setPrevPath(location.pathname);
    return () => {};
  }, []);

  return (
    <div className={classes.root}>
      <Box mx={50}>
        <Box
          my={10}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <h1>{title}</h1>
          <Button
            onClick={handleOpen}
            variant="contained"
            sx={{
              width: "30%",
              "&.MuiButton-contained": {
                backgroundColor: "#950623"
              },
              "&.MuiButton-root": {
                fontWeight: "bold"
              }
            }}
          >
            前の問題
          </Button>
        </Box>
        <Box my={10} sx={{ textAlign: "center" }}>
          <p
            style={{
              lineHeight: "3em"
            }}
          >
            「紅鶴」とは、フラミンゴの別名。
            <br />
            千葉キャンパスの受付カウンター上部にある
            <br />
            フラミンゴの置物に爆弾は隠されているようだ！
            <br />
            　　君たちは、置物の裏から隠された爆弾を見つけることができた。
            <br />
          </p>
        </Box>
        <Box my={10} sx={{ display: "flex", justifyContent: "center" }}>
          <img className={classes.img} src={img} alt="img" />
        </Box>
        <Box my={10} sx={{ textAlign: "center" }}>
          <p
            style={{
              lineHeight: "3em"
            }}
          >
            　　しかし、解除コードがわからない。
            <br />
            メモがついている。「12/5↓　 明日↑」
            <br />
            残り時間内に解除コードを特定しサイトに打ち込めば、爆弾は解除されるようだ。
            <br />
          </p>
        </Box>
        <Box my={10} sx={{ display: "flex", justifyContent: "center" }}>
          <TextField
            className={classes.input}
            onChange={(e) => {
              setReq(e.target.value);
            }}
            id="outlined-basic"
            variant="filled"
            label="答え"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              className: classes.inputBase
            }}
          />
        </Box>
        <Box my={10} sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            onClick={handleClick}
            variant="contained"
            sx={{
              width: "30%",
              "&.MuiButton-contained": {
                backgroundColor: "#950623"
              },
              "&.MuiButton-root": {
                fontWeight: "bold"
              }
            }}
          >
            {buttonMessage}
          </Button>
        </Box>
        <Box
          sx={{
            marginTop: 10,
            marginBottom: 20,
            display: "flex",
            justifyContent: "center"
          }}
        >
          <Accordion sx={{ width: "40%" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>協力要請をする</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{hint}</Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">前の問題の回答確認！</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <img className={classes.img} src={pre_img} alt="img" />
            <img className={classes.img} src={q2_answer} alt="img" />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Quest;
